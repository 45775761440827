<template>
    <div>
        <div class="table_content">
            <commonTitle></commonTitle>
            <!-- 表格内容 -->
            <div class="search">
                <el-form ref="form" :model="form" label-width="80px" :inline="true">
                    <el-form-item label="单元名称:">
                        <el-input v-model="form.name" placeholder="请输入单元名称" size="mini"></el-input>
                    </el-form-item>
                    <button @click.prevent="common()" style="cursor: pointer">搜索</button>
                </el-form>
            </div>
            <el-card>
            <el-table
                    :data="tableData"
                    v-loading="loading"
                    element-loading-text="拼命加载中"
                    style="width:100%"
                    
                    :default-sort="{prop: 'settingTime', order: 'descending'}"
                    :stripe="true"
            >
                <el-table-column
                        label="单元名称"
                        fixed="left"
                >
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" @click="lookHouse(scope.row)">
                            {{scope.row.unitName}}
                        </el-link>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="villageName"
                        label="小区名称"
                >
                </el-table-column>
                <el-table-column
                        prop="towerNum"
                        label="楼幢号"
                >
                </el-table-column>
            </el-table>
            <el-row>
                <el-pagination
                        background
                        style="margin-top: 50px"
                        layout="prev, pager, next"
                        :page-size="15"
                        @current-change="handleCurrentChange"
                        :total="totalNum">
                </el-pagination>
                <el-button @click="back" class="btn" size="small">上一步</el-button>
            </el-row>
            </el-card>
        </div>
    </div>
</template>

<script>

    import commonTitle from "../table/commonTitle";

    export default {
        name: "proUnit",
        data() {
            return {
                tableData: [],//表格数据集
                totalNum: 15,
                select0: '',//搜索值
                form: {
                    name: "",
                    region: '',
                },
                loading: false,
                url: this.$Config.base_server,
                data: {
                    access_token: localStorage.getItem("token"),
                    searchKey: 'towerId',
                    searchValue: JSON.parse(localStorage.getItem("towerData")).towerId,
                    limit: 15
                }
            };
        }, components: {
            commonTitle
        }, mounted() {
            this.common();
        }, methods: {
            common(currentPage = 0) {
                //初始化单元数据
                const that = this;
                that.loading = true;
                that.data.page = currentPage;
                that.data.unitName = that.form.name //搜索框中的值
                $.ajax({
                    url: this.url + "/api-public/opeunit/list",
                    data: that.data,
                    contentType: "application/json",
                    type: "get",
                    success: function (res) {
                        if (res.code == 0) {
                            that.loading = false;
                            that.totalNum = res.count;
                            that.tableData = res.data;
                        }
                    }
                })
            }, lookHouse(row) {
                //查看户室信息
                this.$router.push({name: 'proHouse'});
                //存储单元信息
                localStorage.setItem("unitData", JSON.stringify(row));
            }, changePwd() {
                //修改密码
                window.location.href = "../first_tab/changepwd.html";
            },
            back() {
                //返回按钮
              this.$router.push('/proTower');
            },
            handleSizeChange(val) {
                console.log('每页${val}条');
                this.pagesize = val;
            },
            handleCurrentChange(val) {
                console.log('当前页' + val);
                this.currpage = val;
                this.common(val);
            }, upUnit(row) {
                //修改按钮
                this.$router.push({name: 'upUnit'}); //跳转修改单元页面
                //存储选中的单元信息
                localStorage.setItem("unitData", JSON.stringify(row));
            }
        }
    }
</script>

<style scoped>
    .btn {
        float: right;
        transform: translateY(-34px);
    }
</style>